import { createSignal, Match, Switch } from "solid-js";
import { identifyUser } from "@/lib/analytics";
import { RanchClosedWrapper } from "./RanchCloseWrapper";
import { WildsConnectModal } from "./WildsConnectModal";
import pastureSrc from "@/assets/loader/pasture.png";

export function NewGameLayout() {
  identifyUser();

  const [showModal, setShowModal] = createSignal(true);

  return (
    <div
      class="min-h-screen"
      style={{
        "background-image": `url(${pastureSrc})`,
        "background-size": "cover",
        "background-position": "center bottom",
        "background-repeat": "no-repeat",
      }}
    >
      <Switch>
        <Match when={!showModal()}>
          <RanchClosedWrapper openModal={() => setShowModal(true)} />
        </Match>
        <Match when={showModal()}>
          <WildsConnectModal
            closeModal={() => {
              setShowModal(false);
            }}
            wildsConnected={false}
            isModalOpen={showModal}
            setIsModalOpen={setShowModal}
          />
        </Match>
      </Switch>
    </div>
  );
}
