import { Modal } from "@/components/ui/modal.tsx";
import { Button } from "./ui/button";
import { wildsBg } from "@/assets/images";
import { createQuery } from "@tanstack/solid-query";
import { InventoryService, WildsCommunicationService } from "@/api-client";
import { openLink } from "@/lib/telegram";
import { createEffect, Match, Show, Suspense, Switch } from "solid-js";
import { REWARD_IMAGES } from "@/assets/rewards/reward-images";
import { IS_RANCH_PROD } from "@/config";

function WildsConnectContentPreLaunch(props: {
  closeModal: () => void;
  wildsConnected: boolean;
}) {
  const inventoryQuery = createQuery(() => ({
    queryKey: ["inventory"],
    queryFn: () => InventoryService.getInventory(),
  }));

  createEffect(() => {
    console.log(inventoryQuery.data);
  });

  const eggsCounts = () =>
    inventoryQuery.data?.reduce(
      (acc, item) => {
        if (item.itemId === "bronze-treasure-egg") acc.bronze++;
        if (item.itemId === "silver-treasure-egg") acc.silver++;
        if (item.itemId === "golden-treasure-egg") acc.golden++;
        return acc;
      },
      { bronze: 0, silver: 0, golden: 0 },
    );

  const hatcheningPoints = () => {
    const counts = eggsCounts();
    if (!counts) return 0;
    return counts.bronze + counts.silver * 2 + counts.golden * 5;
  };

  return (
    <div class="relative flex flex-col justify-center rounded-xl bg-[#FFB3B3]">
      <div class="absolute -right-3 -top-3 z-10">
        <Button
          variant="ghost"
          class="min-w-auto h-8 w-8 rounded-full bg-white p-1"
          onClick={props.closeModal}
        >
          <span class="eazy-chat text-outline text-2xl text-red-400">x</span>
        </Button>
      </div>
      <div class="relative flex items-center justify-center rounded-t-xl bg-[#FFE7DF] text-center">
        <img src={wildsBg} class="w-full" />
        <div class="absolute -bottom-3">
          <h3 class="rounded-xl bg-[#3F3A38] px-6 py-1 text-sm font-bold uppercase text-white">
            Alpha Launch 1.0
          </h3>
        </div>
      </div>
      <div class="flex flex-col gap-4 rounded-b-lg bg-[#FFE7DF] p-2 pt-6">
        <div class="flex items-center justify-center px-6">
          <ul class="list-disc space-y-1 rounded-b-lg text-sm leading-tight text-black">
            <li>
              Announcing the highly anticipated release of Kuroro Wilds Alpha, a
              one of a kind creature collecting action RPG!
            </li>
            <li>
              In celebration of this upcoming launch, we’re doing an Airdrop for
              Ranch players, based on the{" "}
              <span class="font-bold">Treasure Eggs</span> you’ve collected.
            </li>
            <li>
              Each Treasure Egg is worth{" "}
              <span class="font-bold">Hatchening points</span>, and the Top 10%
              of Ranch Players with the most points will get a{" "}
              <span class="font-bold">$KURO</span> Airdrop before TGE!
            </li>
            <li>
              Deadline is{" "}
              <span class="font-bold">January 17th at midnight EST</span>
            </li>
          </ul>
        </div>
        <div class="bg-[#4A9285]">
          <div class="flex items-center justify-between gap-2 p-2">
            <div class="flex flex-col items-center justify-center gap-2 bg-[#C5C5C5] bg-opacity-60 p-1">
              <img
                src={REWARD_IMAGES["bronze-treasure-egg"]}
                class="h-[50px] w-[50px] object-contain"
              />
              <span class="text-sm font-bold leading-tight text-white">
                Bronze Egg
              </span>
              <span class="text-2xl font-bold leading-tight text-white">
                +1
              </span>
            </div>
            <div class="flex flex-col items-center justify-center gap-2 bg-[#87B396] p-1">
              <img
                src={REWARD_IMAGES["silver-treasure-egg"]}
                class="h-[50px] w-[50px] object-contain"
              />
              <span class="text-sm font-bold leading-tight text-white">
                Bronze Egg
              </span>
              <span class="text-2xl font-bold leading-tight text-white">
                +2
              </span>
            </div>
            <div class="flex flex-col items-center justify-center gap-2 bg-[#DBCA93] p-1">
              <img
                src={REWARD_IMAGES["golden-treasure-egg"]}
                class="h-[50px] w-[50px] object-contain"
              />
              <span class="text-sm font-bold leading-tight text-white">
                Bronze Egg
              </span>
              <span class="text-2xl font-bold leading-tight text-white">
                +5
              </span>
            </div>
          </div>
          <div>
            <div class="flex items-center justify-center bg-[#374F4B] p-1">
              <span class="text-center text-[12px] font-bold text-white">
                Your Stats
              </span>
            </div>
            <div class="flex">
              <div class="flex flex-1 flex-col items-center justify-center bg-[#4F6460] p-1">
                <span class="text-center text-[12px] text-white">
                  <span class="text-[22px] font-bold">
                    {eggsCounts()?.bronze}
                  </span>{" "}
                  Bronze Eggs
                </span>
                <span class="text-center text-[12px] text-white">
                  <span class="text-[22px] font-bold">
                    {eggsCounts()?.silver}
                  </span>{" "}
                  Silver Eggs
                </span>
                <span class="text-center text-[12px] text-white">
                  <span class="text-[22px] font-bold">
                    {eggsCounts()?.golden}
                  </span>{" "}
                  Golden Eggs
                </span>
              </div>
              <div class="flex flex-1 flex-col items-center justify-center bg-[#6C7E7A] p-1">
                <span class="text-center text-[12px] text-white">
                  Hatchening Points
                </span>
                <span class="text-center text-[29px] font-bold text-white">
                  {hatcheningPoints()}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function WildsConnectContentPostLaunch(props: {
  closeModal: () => void;
  wildsConnected: boolean;
}) {
  const eggStatsQuery = createQuery(() => ({
    queryKey: ["WildsCommunicationService/getUserEggStats"],
    queryFn: () => WildsCommunicationService.getUserEggStats(),
  }));

  const onGo = () => {
    openLink(
      IS_RANCH_PROD
        ? "https://hub.kuroro.com?ref=ranch"
        : "https://hub.kuroro.dev?ref=ranch",
    );
  };

  return (
    <div class="relative flex flex-col justify-center rounded-xl bg-[#FFB3B3]">
      <div class="absolute -right-3 -top-3 z-10">
        <Button
          variant="ghost"
          class="min-w-auto h-8 w-8 rounded-full bg-white p-1"
          onClick={props.closeModal}
        >
          <span class="eazy-chat text-outline text-2xl text-red-400">x</span>
        </Button>
      </div>
      <div class="relative flex items-center justify-center rounded-t-xl bg-[#FFE7DF] text-center">
        <img src={wildsBg} class="w-full" />
        <div class="absolute -bottom-3">
          <h3 class="rounded-xl bg-[#3F3A38] px-6 py-1 text-sm font-bold uppercase text-white">
            Alpha Launch 1.0
          </h3>
        </div>
      </div>
      <div class="flex max-h-[400px] flex-col gap-4 overflow-auto rounded-b-lg bg-[#FFE7DF] p-2 pt-6">
        <div class="flex items-center justify-center px-6">
          <ul class="list-disc space-y-1 rounded-b-lg text-sm leading-tight text-black">
            <li>
              Announcing the highly anticipated release of Kuroro Wilds Alpha, a
              one of a kind creature collecting action RPG, launching{" "}
              <span class="font-bold">Feb 5th!</span>
            </li>
            <li>
              To celebrate, we’re distributing{" "}
              <span class="font-bold">3 Million $KURO</span> to the top 10% of
              players with the most Hatchening points from Eggs in Ranch!
            </li>
            <li>
              Pre-register now - we’re doing daily giveaways for everyone on the
              waitlist!
            </li>
            <li>
              Connect your Ranch account to claim your Airdrop <strong>after wilds Launch!</strong>
            </li>
          </ul>
        </div>
        <div class="flex flex-col items-center justify-center pb-4">
          <Suspense fallback={"Loading..."}>
            <Show when={!props.wildsConnected}>
              <Button
                onClick={onGo}
                size="sm"
                class="eazy-chat relative z-10 min-w-[150px] border border-[#B04831] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] text-xl leading-4 hover:opacity-70"
              >
                <div class="rounded-md"></div>
                <span class="z-10 text-white">Pre Register</span>
              </Button>
            </Show>
            <Show when={eggStatsQuery.data}>
              <div class="flex w-full flex-col py-3 font-bold text-white">
                <div class="flex w-full justify-center gap-3">
                  <div class="flex w-full flex-col items-center justify-center gap-1 bg-[#C5C5C5] px-3 py-1">
                    <img
                      class="w-[50px]"
                      src={REWARD_IMAGES["bronze-treasure-egg"]}
                    />
                    <p>Bronze Egg</p>
                    <p class="text-3xl font-extrabold">+1</p>
                  </div>
                  <div class="flex w-full flex-col items-center justify-center gap-1 bg-[#87B396] px-3 py-1">
                    <img
                      class="w-[50px]"
                      src={REWARD_IMAGES["silver-treasure-egg"]}
                    />
                    <p>Silver Egg</p>
                    <p class="text-3xl font-extrabold">+2</p>
                  </div>
                  <div class="flex w-full flex-col items-center justify-center gap-1 bg-[#DBCA93] px-3 py-1">
                    <img
                      class="w-[50px]"
                      src={REWARD_IMAGES["golden-treasure-egg"]}
                    />
                    <p>Golden Egg</p>
                    <p class="text-3xl font-extrabold">+5</p>
                  </div>
                </div>
                <div class="w-full py-2">
                  <p class="w-full bg-[#2D2D2DA8] p-2 text-center">
                    Your Stats
                  </p>
                  <div class="flex">
                    <div class="w-full bg-[#837C7B] p-4">
                      <p class="text-2xl">
                        {eggStatsQuery.data?.bronzeTreasureEggs}{" "}
                        <span class="text-lg font-light">Bronze Eggs</span>
                      </p>
                      <p class="text-2xl">
                        {eggStatsQuery.data?.silverTreasureEggs}{" "}
                        <span class="text-lg font-light">Silver Eggs</span>
                      </p>
                      <p class="text-2xl">
                        {eggStatsQuery.data?.goldenTreasureEggs}{" "}
                        <span class="text-lg font-light">Golden Eggs</span>
                      </p>
                    </div>
                    <div class="flex w-full flex-col items-center justify-center bg-[#95908F]">
                      <p class="text-lg font-light">Hatchening Points</p>
                      <p class="text-5xl">
                        {eggStatsQuery.data?.hatcheningPoints}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Show>
          </Suspense>
        </div>
      </div>
    </div>
  );
}

export function WildsConnectModal(props: {
  isModalOpen: () => boolean | null;
  setIsModalOpen: (value: boolean) => void;
  closeModal: () => void;
  wildsConnected: boolean;
}) {
  const isAfterWildsLaunch = true;

  return (
    <Modal
      open={!!props.isModalOpen()}
      onOpenChange={props.setIsModalOpen}
      onPointerDownOutside={props.closeModal}
    >
      <Show when={isAfterWildsLaunch}>
        <WildsConnectContentPostLaunch
          closeModal={props.closeModal}
          wildsConnected={props.wildsConnected}
        />
      </Show>
    </Modal>
  );
}
