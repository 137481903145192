import { ErrorFallback } from "@/components/ErrorFallback.tsx";
import { ErrorBoundary, Suspense } from "solid-js/web";
import { SDKProvider } from "@tma.js/sdk-solid";
import { Route, Router } from "@solidjs/router";
import { GameBootstrapRoute } from "@/routes/GameBootstrapRoute.tsx";
import { DebugRoute } from "@/routes/DebugRoute.tsx";
import { createEffect, lazy, onCleanup, onMount } from "solid-js";
import { useConnectionEvent } from "@/components/connection.ts";
import { QueryClient, QueryClientProvider } from "@tanstack/solid-query";
import { TonWalletProvider } from "@/components/TonConnectUI.tsx";
import { showToast, Toaster } from "@/components/ui/toast.tsx";
import Loading from "./components/Loading";
import UpgradesRoute from "./routes/UpgradesRoute";
import FriendsRoute from "./routes/FriendsRoute";
import QuestsRoute from "./routes/QuestsRoute";
import AirdropRoute from "./routes/AirdropRoute";
import * as R from "remeda";

const OnboardingRoute = lazy(() => import("@/components/OnboardingRoute.tsx"));
import CloseRoute from "@/routes/CloseRoute";
import ShopRoute from "./routes/ShopRoute";
import { IS_RANCH_PROD } from "./config";
import SettingsRoute from "./routes/SettingsRoute";
import { settingsStoreActions } from "./components/settings-store";
import { DebugThrowErrorRoute } from "@/routes/DebugThrowErrorRoute.tsx";
import MatchRoute from "./routes/MatchRoute";
import { RaffleMachineRoute } from "@/routes/RaffleMachineRoute.tsx";
import { NewGameLayout } from "./components/NewGameLayout";

const queryClient = new QueryClient();

export const SuspenseFallback = () => {
  return <Loading />;
};

const startBgMusicOnce = R.once(settingsStoreActions.enableBgMusic);

const stopStartMusicOnVisibilityChange = () => {
  function handleVisibilityChange() {
    if (document.hidden) {
      settingsStoreActions.disableBgMusic();
    } else {
      settingsStoreActions.enableBgMusic();
    }
  }

  onMount(() => {
    createEffect(() => {
      document.addEventListener("visibilitychange", handleVisibilityChange);
    });
  });

  onCleanup(() => {
    document.removeEventListener("visibilitychange", handleVisibilityChange);
  });
};

export function App() {
  stopStartMusicOnVisibilityChange();

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<SuspenseFallback />}>
          <SDKProvider acceptCustomStyles debug>
            <TonWalletProvider>
              <div onClick={startBgMusicOnce} class="h-[100vh]">
                <Router preload={false}>
                  <Route path="/" component={NewGameLayout} />
                  <Route path="/play" component={NewGameLayout} />
                </Router>
              </div>
            </TonWalletProvider>
          </SDKProvider>
        </Suspense>
      </QueryClientProvider>
    </>
  );
}
