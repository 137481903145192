import { createSignal, createEffect } from "solid-js";
import { ConnectSocialsService } from "@/api-client";
import pastureSrc from "@/assets/loader/pasture.png";
import logoSrc from "@/assets/loader/loading_screen_logo.webp";
import heartSrc from "@/assets/loader/heart.png";
import "./Views.css";
import { Button } from "./ui/button";
import { createQuery } from "@tanstack/solid-query";

export function RanchClosedWrapper(props: { openModal: () => void }) {
  const [wildsConnected, setWildsConnected] = createSignal(false);
  const [isWildsConnectModalOpen, setIsWildsConnectModalOpen] = createSignal<
    boolean | null
  >(true);

  const connectedSocialsQuery = createQuery(() => ({
    queryKey: ["GameLayout/getConnectedSocials"],
    queryFn: () => ConnectSocialsService.getConnectedSocials(),
  }));

  createEffect(() => {
    if (
      connectedSocialsQuery.data !== undefined &&
      isWildsConnectModalOpen() === null
    ) {
      setWildsConnected(connectedSocialsQuery.data?.zephyrus ?? false);
      setIsWildsConnectModalOpen(!wildsConnected());
    }
  }, [connectedSocialsQuery.data]);

  return (
    <div
      class="relative z-[90000] flex h-[100vh] w-[100vw] flex-col items-center justify-center"
      style={{
        "background-image": `url(${pastureSrc})`,
        "background-size": "cover",
        "background-position": "center bottom",
        "background-repeat": "no-repeat",
      }}
    >
      <img src={logoSrc} class="h-full w-full object-cover" />
      <div
        class="absolute bottom-[10%] left-[50%] box-border w-[95%] translate-x-[-50%] transform bg-black bg-opacity-65"
        style={{
          bottom: "10%",
        }}
      >
        <div class="flex items-center justify-center gap-2 p-2">
          <img src={heartSrc} class="h-10 w-10" />
          <h2 class="eazy-chat text-[18px] font-bold text-white">
            Thank you to everyone who has enjoyed playing Ranch!
          </h2>
        </div>
        <div
          class="eazy-chat p-4 text-center text-xl text-white"
          style={{
            background: "rgba(0, 0, 0, 0.6)",
          }}
        >
          <div class="flex flex-col items-center justify-center gap-4 px-6">
            <ul class="list-disc space-y-2 rounded-b-lg text-left text-sm text-white">
              <li>
                Ranch will be closed during the Wilds P2A Launch, but may return
                in the future.
              </li>
              <li>
                To celebrate our Launch, we’re giving away 3 million $KURO to
                the top 10% of players from Ranch!
              </li>
              <li>Learn more and see if you qualified:</li>
            </ul>
            <Button
              onClick={() => {
                props.openModal();
              }}
              size="sm"
              class="eazy-chat pointer-events-auto relative z-10 min-w-[150px] border border-[#B04831] bg-gradient-to-b from-[#FCB74F] to-[#FA9044] text-[15px] leading-4 hover:opacity-70"
            >
              <div class="rounded-md"></div>
              <span class="z-10 text-[15px] text-white">
                Wilds Launch Announcement
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
